@import '../../scss/base.module';

.historical-metrics-redesign {
  padding: $spacing-xl $spacing-l;
  background: $info;

  @include from-laptop {
    padding: $spacing-xxl 0;
  }
}
.historical-metrics-redesign__content {
  display: flex;
  gap: $spacing-m;
  @include max-container-homepage;
  @include from-laptop {
    gap: $spacing-l;
  }
}
.historical-metrics-redesign__section {
  background-color: $white;
  border-radius: $spacing-l;
  flex: 1;
  text-align: center;
  color: $dark;
  @include font-title;
  padding: $spacing-m;

  @include from-laptop {
    gap: $spacing-l;
  }
}
.historical-metrics-redesign__number {
  @include font-m;

  @include from-laptop {
    @include font-xxxl;
  }
}
.historical-metrics-redesign__text {
  @include font-xxs;
  @include font-bold;
  @include from-laptop {
    @include font-s;
  }
}
