@mixin landing-heading-redesign {
  @include font-xxxxl;
  padding-bottom: $spacing-l;
  margin-bottom: 0;
  color: $dark;

  @include from-laptop {
    @include font-xxxxxl;
  }
}

@mixin landing-heading-1 {
  @include font-bold;
  font-size: $font-xxxl;
  line-height: normal;
  color: $dark;
}

@mixin landing-heading-2 {
  @include font-bold;
  font-size: $font-l;
  line-height: normal;
  color: $dark;
}

@mixin landing-heading-3 {
  font-size: $font-xxxxxl;
  line-height: $line-height-xxxxxl;
  color: $white;
  font-weight: $font-regular;
  text-align: center;
}

@mixin landing-description {
  font-size: $font-m;
  line-height: $spacing-l;
  color: $dark;
}
