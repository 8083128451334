@import '../../scss/base';

.testimonials-carousel {
  padding: 14 * $spacing-xxs 0px;

  @include from-laptop {
    padding: 22 * $spacing-xxs 0px;
  }

  &__header {
    font-weight: bold;
    text-align: center;
    padding: 0px $spacing-m $spacing-m;
  }

  &__title {
    @include landing-heading-redesign;
    margin: 0;
  }

  &__carousel {
    .slide {
      &:first-child {
        padding-left: $spacing-l;
      }
      &:last-child {
        padding-right: $spacing-l;
      }

      &:not(:first-child):not(:last-child) {
        padding-left: $spacing-m;
        padding-right: $spacing-m;
      }

      @include from-phablet {
        &:first-child {
          padding-left: calc($spacing-xxxxl * 2);
        }
        &:last-child {
          padding-right: calc($spacing-xxxxl * 2);
        }

        &:not(:first-child):not(:last-child) {
          padding-left: $spacing-xxxxl;
          padding-right: $spacing-xxxxl;
        }
      }
    }
  }

  &__arrows {
    text-align: right;
    margin-right: calc($spacing-xxxxl * 2);
    margin-bottom: $spacing-xl;
    max-width: 1000px;
    margin: 0 auto;
    display: none;

    @include from-phablet {
      display: block;
    }
  }

  &__trustpilot {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: $spacing-l;

    @include from-phablet {
      margin-top: $spacing-xxl;
    }
  }
}
