@import '../base';

@mixin grid-ie-calc($items: 30, $wrap-on: 3, $grid-gap: 0) {
  $current-row: 1;
  $current-column: 1;

  @if $grid-gap > 0 {
    & {
      margin: $grid-gap * 0.5;

      @supports (grid-gap: $grid-gap) {
        margin: 0;
      }
    }
  }

  @for $i from 1 through $items {
    @if $current-column > $wrap-on {
      $current-column: 1;
      $current-row: $current-row + 1;
    }

    &:nth-child(#{$i}) {
      -ms-grid-row: $current-row;
      -ms-grid-column: $current-column;
    }

    $current-column: $current-column + 1;
  }
}

$one-col-max-with: 672px;
$gutter: $spacing-xl;

.wrapper {
  margin: 0 auto;
  padding: 0 $spacing-xl;
  max-width: $max-width;
  @include until-phablet {
    padding: 0;
  }
}

.one-col {
  max-width: $one-col-max-with;
  @include until-tablet {
    max-width: none;
  }
  &--center {
    margin: 0 auto;
  }
}

.two-cols {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: $gutter;

  & > * {
    @include grid-ie-calc(18, 2, $gutter);
  }

  @include until-phablet {
    grid-template-columns: 100%;
    grid-column-gap: 0;
  }
}

.three-cols {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1fr 1fr;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: $gutter;

  & > * {
    @include grid-ie-calc(18, 3, $gutter);
  }

  @include until-phablet {
    grid-template-columns: 100%;
    grid-column-gap: 0;
  }
}
