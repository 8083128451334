@import '../scss/base';

.city-list {
  display:flex;
  flex-direction: column;

  &--only-first-ten {
    a:nth-child(n+10) {
      display:none;
      @include from-tablet {
        display: grid;
      }
    }
  }

  &--redesign {
    a {
      @include from-tablet {
        display: grid;
      }
    }
  }

  a {
    padding: $spacing-xs 0px;
    @include from-tablet {
      padding: 0px
    }
  }

  @include from-tablet {
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    grid-gap: $spacing-xs $spacing-xl;
  }

  @include from-desktop {
    display: grid;
    grid-template-columns: repeat(6, minmax(0, 1fr));
    grid-gap: $spacing-xs $spacing-s;
  }

  &__title-container {
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  &__title-number {
    padding-bottom: $spacing-xl;
    display: none;
    @include from-phablet {
      display: inline-flex;
    }
  }

  a {
    color: $interaction-dark;
  }

  &__arrow-wrapper {
    padding-left: $spacing-s;
  }

  &__show-button {
    padding-top: $spacing-xs;
    @include font-bold;
    display: block;
    align-items: center;

    @include from-tablet {
      display: none;
    }

    &--mobile {
      display: block;
      @include from-tablet {
        display: none;
      }

    }
  }

  &__show-button-chevron {

    padding-left: $spacing-xs;
    svg {
      width: $spacing-xs;
      height: $spacing-xs;
    }
  }

  &__show-button-chevron-title {
    svg {
      width: $spacing-s;
      height: $spacing-s;
    }
  }
}
