@import '../../scss/base.module';

.our-guarantee {
  background-color: $secondary-blue-light;
  text-align: center;
  padding: $spacing-xxxl $spacing-m;
  @include font-title;

  @include from-laptop {
    padding: 100px 0px;
  }

  &__title {
    @include landing-heading-redesign;
    @include font-title;
  }

  &__points {
    @include max-container-homepage;
    @include from-laptop {
      display: grid;
      grid-template-columns: auto auto;
    }
  }

  &__point {
    background-color: $white;
    padding: $spacing-m;
    margin: $spacing-m 0px;
    border-radius: $spacing-l;
    @include from-laptop {
      margin: $spacing-m;
      padding: $spacing-xl;
    }
  }

  &__point-title {
    @include font-xxxl;
    margin: $spacing-m 0px;
  }

  &__point-text {
    @include font-m;
    color: $medium;
  }

  img {
    width: 70px;
  }

  &__cta {
    width: 100%;
    margin-top: $spacing-l;
  }
}
