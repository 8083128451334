@import '../../scss/base.module';

$MAX-FAQ-WIDTH: 670px;

.faq-section {
  padding: 64px $spacing-m $spacing-xl;

  @include from-laptop {
    padding: 64px $spacing-xl $spacing-xl;
  }

  &--redesign {
    background-color: $secondary-pink-light;
    padding: 14 * $spacing-unit $spacing-l;

    @include from-laptop {
      padding: 120px 0px;
    }
  }

  &__accordion-wrapper {
    max-width: $MAX-FAQ-WIDTH;
    margin: auto;

    &--redesign {
      @include max-container-homepage;
    }
  }

  &__title {
    @include landing-heading-1;
    @include font-title;
    @include font-xxxxl;

    text-align: center;
    color: $interaction-dark;
    padding-bottom: $spacing-l;
    max-width: $MAX-FAQ-WIDTH;
    margin: 0 auto;

    &--redesign {
      @include landing-heading-redesign;
    }
  }

  &__bottom {
    padding: $spacing-xl 0;
    text-align: center;

    a {
      text-decoration: underline;
      white-space: nowrap;
    }
  }
}
