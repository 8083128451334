@import '../../scss/base';

.testimonial {
  background-color: $ultra-light;
  padding: $spacing-xl $spacing-l;
  border-radius: $spacing-l;

  > div {
    &:not(:last-child) {
      margin-bottom: $spacing-m;
    }
  }


  &__comment {
    color: $medium;
    font-size: $font-l;
  }

  &__author {
    color: $dark;
    font-weight: $font-bold;
    font-size: $font-m;
  }

  &__country {
    color: $dark;
    font-size: $font-s;
  }

}
