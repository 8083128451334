@import '../../scss/base.module';

.find-your-home {
  background-color: $white;
  @include font-title;
  padding: 14 * $spacing-unit $spacing-l;
  text-align: center;
  display: flex;
  flex-direction: column;

  img {
    padding-bottom: $spacing-l;

    @include from-laptop {
      padding-bottom: $spacing-xxl;
    }
  }

  @include from-laptop {
    padding: 120px 0px;
    place-items: center;
  }

  &__title {
    @include font-xxxxl;
    @include font-title;
    padding-bottom: $spacing-m;
    @include from-laptop {
      @include font-xxxxxl;
      padding-bottom: $spacing-l;
    }
  }
}
