@import '../scss/base';

.city {
  position: relative;
  max-width: 500px;
  background: var(--sah-ultra-light);
  overflow: hidden;
  border-radius: $spacing-xl !important;

  @include general-hover;

  @include until-phablet {
    display: inline-block;
    min-width: 85%;
  }

  &-image {
    margin: 0;
    overflow: hidden;
    max-height: 65%;

    &__small-display {
      max-height: 80% !important;
    }

    @include from-phablet {
      max-height: 60%;
    }

    @include from-laptop {
      max-height: 70%;
    }

    @include from-desktop {
      max-height:80%;
    }
  }

  &-info {
    position: absolute;
    top: 21%;
    left: 0;
    width: 100%;
    text-align: center;
    color: white;

    &-name {
      @include font-m;
      @include font-bold;
      display: block;
    }

    &-homes {
      @include font-s;
      text-transform: lowercase;
    }

    &-country {
      @include font-m;
      @include font-bold;
      display: block;
      text-transform: capitalize;
    }
  }

  &-count {
    padding: $spacing-s $spacing-l;
    @include font-s;
    text-align: left;
    color: var(--sah-dark) !important;

    &__list {
      padding: 0;
      list-style: none;
      &-item {
        @include font-s;
        margin-bottom: $spacing-xs;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}
