@import '../scss/base';

.city-redesign {
  position: relative;
  width: 300px;
  min-height: 430px;
  padding: $spacing-l;
  overflow: hidden;
  border-radius: $spacing-l;
  border: solid $light 1px;
  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  @include general-hover;

  @include from-phablet {
    width: 270px;
  }

  &-image {
    margin: 0;
    overflow: hidden;
    max-height: 65%;
    border-radius: $spacing-m;
    width:100%;
    height: 100%;
    flex-grow: 1;
    position: relative;

    &__small-display {
      max-height: 80% !important;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  &-info {
    position: absolute;
    top: 21%;
    left: 0;
    width: 100%;
    text-align: center;
    color: white;

    &-name {
      @include font-xxxl;
      display: block;
      margin: 0px;
      color: $dark;
    }

    &-homes {
      @include font-m;
      color: $medium;
      text-transform: uppercase;
    }
  }

  &-count {
    margin-top: $spacing-s;
    text-align: center;
    color: var(--sah-dark) !important;
  }
}
