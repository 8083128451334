@import '@spotahome/ui-library/lib/scss/base.module';

@mixin general-hover {
  transition: box-shadow 0.1s ease-out, transform 0.05s ease-out,
    opacity 0.1s ease-out;

  &:hover,
  &:focus {
    opacity: 0.85;
    transform: translate(0px, -1px);
    box-shadow: 0 2px $spacing-xs 0px rgba(0, 0, 0, 0.2);
  }

  &:active {
    box-shadow: inset 0px -2px 0px 0px rgba(0, 0, 0, 0.2);
    transform: translate(0px, 2px);
  }
}

@mixin landing-service-feature {
  @include font-s;
  font-weight: bold;
  line-height: 21px;
}

@mixin landing-description--big {
  @include font-bold;
  font-size: $font-l;
  line-height: normal;
  color: $dark;
}

@mixin landing-box-shadow {
  box-shadow: 0 $spacing-m $spacing-xl 0 rgba(0, 42, 77, 0.05);
}

@mixin rounded-style {
  padding: 0 $spacing-l;
  line-height: $spacing-xxl;
  @include until-tablet {
    padding: 0 $spacing-s;
  }
  border: 1px solid $light;
  border-radius: $border-radius;
}
