@import '@spotahome/landings-common/src/scss/base';

$city-selector-items-height: 56px;
$city-selector-cta-width: 56px;

.city-selector {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease-out;
  margin-bottom: $spacing-m;
  flex-wrap: wrap;

  > div {
    border: 1px solid $light;
    border-radius: 4px;

    @include from-laptop {
      border-width: 0px;
    }
  }

  @include from-laptop {
    padding: 8px;
    background-color: #ebebeb; // @TO-DO: Add this color to palette if AutocompleterRework experiment wins.
    border-radius: 999px;
    flex-wrap: nowrap;

    input::placeholder {
      @include font-bold;
    }
  }

  &--shaking {
    animation: shake 0.8s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  }

  &__autocompleter {
    width: 100%;
    z-index: 2;

    input {
      color: $dark;

      &::placeholder {
        color: $dark;
      }
    }

    &--within-input {
      width: 250px;
      position: relative;
      flex-grow: 1;
      border: none !important;
      border-radius: 50px !important;

      > div {
        height: 80px;

        @include font-regular;

        @include from-laptop {
          height: auto;
        }
      }

      input {
        padding-left: $spacing-m;
      }
    }

    @include from-laptop {
      width: 250px;
      position: relative;
      flex-grow: 1;
    }
  }

  &__new-button {
    margin-top: $spacing-m;

    &-icon {
      display: none;
    }

    &--within-input {
      margin-top: 0;
      border-radius: 100%;
      width: 64px;
      height: 80px;
      padding: 0 $spacing-xs 0 $spacing-l;
      @include font-regular;
    }

    &-icon--within-input {
      display: inline;
    }

    &-text--within-input {
      display: none;
    }

    @include from-laptop {
      margin-top: 0;
      border-radius: 100%;
      width: $city-selector-cta-width;
      height: $city-selector-cta-width;
      padding: 0;
      &-icon {
        display: inline;
      }

      &-text {
        display: none;
      }
    }
  }
  &__moving-in-with-someone {
    display: flex;
    flex-direction: row;
    margin: auto
  }

  &__moving-in-with-someone-text {
    padding-right: 5px;
  }

  &__button {
    margin-top: $spacing-m;
    outline: none;
    border: none;
    padding: 0;
    background-color: transparent;
    cursor: pointer;
    width: 100%;


    @include from-laptop {
      margin-top: 0;
      width: auto;
    }

    &-icon {
      display: none;

      @include from-laptop {
        display: flex;
        background-color: $primary;
        border: none;
        width: $city-selector-cta-width;
        height: $city-selector-cta-width;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        cursor: pointer;
        flex-shrink: 0;
        &:hover {
          background-color: $primary-light;
        }
      }
    }

    &-icon--within-input {
      display: flex;
      background-color: $primary;
      border: none;
      width: $city-selector-cta-width;
      height: $city-selector-cta-width;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      cursor: pointer;

      flex-shrink: 0;

      &:hover {
        background-color: $primary-light;
      }
    }

    &-mobile {
      @include font-m;
      @include font-bold;

      display: flex;
      justify-content: center;
      align-items: center;
      flex-shrink: 0;
      height: $city-selector-items-height;
      background: $primary;
      color: white;
      outline: none;
      border-radius: 4px;
      width: 100%;

      &:hover {
        background-color: $primary-light;
      }

      @include from-laptop {
        display: none;
      }
    }
  }

  @keyframes shake {
    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }
}
