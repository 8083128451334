@import '@spotahome/landings-common/src/scss/base';

.landlord-section {
  background-color: $tertiary;
  @include font-title;
  padding: 14 * $spacing-unit $spacing-l;
  text-align: center;
  display: flex;
  flex-direction: column;

  @include from-laptop {
    padding: 120px 0px;
    place-items: center;
  }

  &__title {
    @include font-xxxxl;
    @include font-title;
    padding-bottom: $spacing-m;
    @include from-laptop {
      @include font-xxxxxl;
    }
  }

  &__text {
    @include font-m;
    color: $medium;
  }

  &__cta {
    margin-top: $spacing-l;
  }
}
