@import '../scss/base';

.cities-showcase {
  margin: 0 auto;
  &__actions {
    text-align: center;

    @include until-phablet {
      display: none;
    }
  }

  &__top-cities {
    @include until-phablet {
      padding: 0px !important;
    }

    &--redesign {
      padding-left: 0;
      padding-right: 0;
      padding-bottom: 0;
    }
  }

  &__other-cities {
    padding: 0px $spacing-xxxxl $spacing-xxxxl !important;

    @include until-phablet {
      padding: 0px $spacing-l $spacing-xl !important;
    }
  }

  &__header {
    display: flex;
    padding: 0px $spacing-xxxxl $spacing-l;
    font-weight: bold;

    @include until-phablet {
      padding: 0px $spacing-m;
    }

    &--redesign {
      display: block;
      text-align: center;
    }

    @include from-phablet {
      padding: 0px $spacing-xxxxl $spacing-l;
    }
  }

  &__header-title {
    &--redesign {
      @include landing-heading-redesign
    }
  }

  &__carousel {
    .slide:nth-child(1) {
      margin-left: $spacing-m;
      @include from-phablet {
        margin-left: $spacing-xxxxl;
      }
    }

    &--redesign {
      .slide:first-child {
        margin-left: $spacing-xl;
      }
    }
  }

  &__arrows {
    flex: 1 1 0;
    text-align: right;
    align-self: center;
    display: none;

    &--redesign {
      text-align: right;
      margin-right: calc($spacing-xxxxl * 2);
      margin-bottom: $spacing-xl;
      max-width: 1000px;
      margin: 0 auto;
    }

    @include from-phablet {
      display: block;
    }
  }

  &__action {
    background: white;
    border-radius: $border-radius;
    padding-left: $spacing-xl;
    padding-right: $spacing-xl;
    line-height: 56px;
    text-decoration: none;
    color: $primary;
    display: inline-block;
    position: relative;
    transition: background 0.1s ease-out, translate 0.1s ease-out,
      color 0.1s ease-out, border 0.1s ease-out;
    border: 1px solid $primary;
    &:hover {
      border: 1px solid $primary-light;
      color: $primary-light;
    }
  }
}
