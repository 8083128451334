@import '@spotahome/landings-common/src/scss/base';

.guarantee-section {
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: $spacing-xxl;
  padding: 14 * $spacing-xxs $spacing-l;
  @include max-container-homepage;
  @include from-laptop {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: $spacing-l;
    grid-row-gap: $spacing-l;
    padding-top: 31 * $spacing-xxs;
    padding-bottom: 0;
  }

  &__guarantee {
    position: relative;
    transition: transform 0.5s ease-out;
    will-change: transform;
    background-color: $white;

    @include from-laptop {
      padding: $spacing-xxxl $spacing-l $spacing-l;
      border: 1px solid $light;
      border-radius: $spacing-m;
    }

    &--reverse {
      flex-direction: row-reverse;
      @include from-laptop {
        .guarantee-section__copies {
          text-align: left !important;
        }
      }
    }

    &--is-sticky {
      position: sticky;
      top: 0px;
    }
  }

  &__content-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    height: 100%;

    @include from-laptop {
      img {
        order: 3;
        margin-top: auto;
      }
    }
  }

  &__title {
    @include font-xxxxl;
    @include font-title;
    padding-bottom: $spacing-m;
    @include from-laptop {
      @include font-xxxxl;
      order: 1;
    }
  }

  &__subtitle {
    @include font-title;
    color: $medium;
    @include font-m;

    @include from-laptop {
      @include font-xl;
      order: 2;
    }
  }

  &__copies {
    align-content: center;
    @include font-title;
    text-align: center;

    @include from-laptop {
      max-width: 33%;
      text-align: right;
      padding: 0px $spacing-l;
    }
  }
}
