@import './base';

.grid__wrapper {
  @include from-laptop {
    padding: 64px 0;
  }

  @include only-tablet {
    padding: 60px;
  }

  @include until-phablet {
    padding: $spacing-xl $spacing-l;
  }

  margin: 0 auto;
  padding: 100px 0;
}

.grid__wrapper--inner {
  max-width: 1024px;
  margin: 0 auto;
}
