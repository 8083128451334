@import '../scss/base';

.property-type-section {
  margin: 0 auto;

  &__container {
    &--redesign {
      @include max-container-homepage;
      padding-top: 14 * $spacing-unit!important; //Override style from default that is before in staging
      padding-bottom: 14 * $spacing-unit!important; //Override style from default that is before in staging
      @include from-laptop {
        padding-top: 120px!important; //Override style from default that is before in staging
        padding-bottom: 120px!important; //Override style from default that is before in staging
      }
    }
  }

  &__header {
    display: flex;
    padding: 0px $spacing-m;

    font-weight: bold;

    &--redesign {
      display: block;
      text-align: center;
    }

    @include from-phablet {
      padding: 0px $spacing-xxxxl $spacing-l;
    }
  }

  &__header-title {
    @include font-xxl;

    &--redesign {
      @include landing-heading-redesign;
      margin: 0;
    }
  }

  &__carousel {
    .slide:first-child {
      margin-left: $spacing-m;

      @include from-phablet {
        margin-left: $spacing-xxxxl;
      }
    }

    &--redesign {
      .slide:first-child {
        margin-left: $spacing-xl;
        @include from-laptop {
          margin-left: 0;
        }
      }
    }
  }

  &__arrows {
    flex: 1 1 0;
    text-align: right;
    align-self: center;
    display: none;

    @include from-phablet {
      display: block;
    }
  }
}
