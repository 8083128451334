@import '../../scss/base.module';

.faq-accordion {
  &--redesign {
    > * + * {
      margin-top: $spacing-m;
    }
  }
}

.faq-accordion-item {
  padding: $spacing-xs 0 $spacing-m;
  border-bottom: 1px solid $light;
  white-space: pre-line;

  &--redesign {
    background-color: $white;
    padding: $spacing-l;
    border-radius: $spacing-s;
  }

  &__top {
    @include landing-heading-2;
    padding: $spacing-m 0;
    margin: 0;

    &--landlord {
      color: $secondary;
    }

    &--tenant {
      color: $interaction;

      &--redesign {
        color: $dark;
        padding: 0;
      }
    }

    @include only-mobile {
      @include font-m;
    }
  }

  &__content {
    padding-right: $spacing-m;
    @include landing-description;
    @include only-mobile {
      @include font-s;
    }

    &--redesign {
      padding-top: $spacing-m;
      color: $medium;
    }

    ul {
      margin: 0;
    }
  }

  &__icon {
    @include font-xxxl;
  }
}
