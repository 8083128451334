@import '../scss/base';

.property-type-section-card {
  margin-right: $spacing-l;
  background-color: $white;
  overflow: hidden;
  border-radius: $spacing-l;
  border: solid $light 1px;
  padding-bottom: $spacing-xxl;

  @include from-tablet {
    padding-bottom: $spacing-xxl;
  }

  &--redesign {
    max-width: 300px;
    padding: $spacing-l;

    @include from-phablet {
      max-width: 270px;
    }
    @include from-laptop {
      &:last-child {
        margin-right: 0;
      }
    }
  }

  &__image {
    margin: 0;
    overflow: hidden;

    img {
      min-height: 250px;
    }

    &--redesign {
      border-radius: $spacing-m;
      border: solid $light 1px;

      img {
        min-height: initial;
      }
    }
  }

  &__info {
    text-align: left;
    padding: $spacing-s;
    display: flex;
    flex-direction: column;

    &--redesign {
      padding: 0;
      margin-top: $spacing-m;
    }
  }

  &__info-title {
    @include font-m;
    @include font-bold;
    color: $dark;
    margin: $spacing-s 0 $spacing-xxs 0;

    &--redesign {
      @include font-xs;
      margin-bottom: $spacing-m;

      @include from-tablet {
        @include font-xs;
      }
     }
  }

  &__info-title-label {

    &--redesign {
      text-transform: uppercase;
      background-color: $secondary-pink;
      padding: $spacing-xs $spacing-s;
      border-radius: $spacing-l;
    }
  }

  &__info-description {
    @include font-s;
    color: $medium;
  }
}
