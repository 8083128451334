@import '@spotahome/ui-library/lib/scss/init.module';
@import '@spotahome/toast-grid/scss/grid';

@import './base';
@import './grid/grid';
@import './layout';

.mb-m {
  margin-bottom: $spacing-m;
}

.mb-xl {
  margin-bottom: $spacing-xl;
}
