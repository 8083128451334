@import '../../scss/base.module';

$rounded-radius: 4px;

.collapsible {
  &--inline {
    display: inline;

    .collapsible__header {
      display: inline-flex;
    }
  }
}

.collapsible__content-wrapper {
  max-height: 0;
  transition: all .5s ease;
  overflow-y: hidden;
  &--opened {
    max-height: unset;
    overflow-y: visible;
  }
  &--rounded {
    transform: translateY(-3px);
    border-bottom-left-radius: $rounded-radius;
    border-bottom-right-radius: $rounded-radius;
  }
}

.collapsible__content-body {
  &--rounded {
    border-bottom-left-radius: $rounded-radius;
    border-bottom-right-radius: $rounded-radius;
  }
}

.collapsible__header {
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  transition: margin-bottom 0.5s ease;

  &--inline {
    display: inline-flex;
  }

  &--rounded {
    border-radius: 4px;
    border-style: solid;
    border-color: transparent;
    &-open {
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;
    }
  }
}

.collapsible--collapsed {
  .collapsible__header {
    margin-bottom: 0;
  }
}
