@import '../../scss/base';

.arrow-button {
  border: 1px solid var(--sah-light);
  border-radius: 50px;
  width: 35px;
  height: 35px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-left: $spacing-xs;
  cursor: pointer;

  &__redesign {
    border: 1px solid var(--sah-interaction-dark);

    &--disabled {
      border: 1px solid var(--sah-light);
      cursor: not-allowed;
    }
  }
}
